import React, {useState} from 'react'
import styled from 'styled-components';
import { graphql } from "gatsby";

import Header from '../components/Header/header';
import Logo from '../assets/logo.svg';
import '../components/layout.css'
import CardsAchives from '../components/CardAchives';
import Partners from '../components/Partners';
import Footer from '../components/Footer/footer';
import CarouselProjects from '../components/Carousel/CarouselProjects';

import {ContainerCarousel, ContainerL} from "../ui/containers";
import Modal from "../ui/modal";






const Tittle = styled.div`
  font-weight: 200;
  color: white;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  @media (max-width: 968px) {
    font-size: 48px;
    line-height: 58px;
  }
`;

const Border = styled.div`
  width: 111px;
  border-top: 2px solid #4285F4;
  margin-top: 28px;
  margin-bottom: 28px;
`;

const Text = styled.div`
  font-weight: 300;
  max-width: 50%;
  font-size: 16px;
  line-height: 27px;
  /* or 169% */
  @media (max-width: 968px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
  }

  color: #FFFFFF;
`;

const Text2 = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  margin-top: 120px;
  margin-right: 30px;
  /* or 169% */
  @media (max-width: 968px) {
    font-size: 14px;
    line-height: 24px;

  }
  @media (max-width: 768px) {
    margin-top: 100px;
    margin-right: 0 !important;
  }

  color: #FFFFFF;
`;

const ContainerAbout = styled.div`

`;

const Img = styled.div`

  background-image: url(${props => props.img ? props.img : ''}); /**устанавливаем картинку на фон**/
  width: 400px;
  background-size: cover;
  height: 620px;
  border-radius: 19px;
  @media (max-width: 1300px) {
    width: 300px;
    height: 520px;
  }
  @media (max-width: 900px) {
    width: 200px;
    height: 370px;
  }
  @media (max-width: 700px) {
    width: 219px;
    height: 340px;
  }
  @media (max-width: 450px) {
    width: 169px;
    height: 270px;
  }
  @media (max-width: 350px) {
    width: 139px;
    height: 270px;

  }
`;

const BackImg = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(11, 10, 10, 0.75) 0%, rgba(11, 10, 10, 0.5) 49.48%, rgba(8, 7, 7, 0.75) 100%);
`;


const BlockStroitel = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const BlockImg = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 125px;
  }
  @media (max-width: 468px) {
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: 125px;
  }
`;
const Otstup = styled.div`
  margin-top: 120px;
  @media (max-width: 568px) {
    margin-top: 45px;
  }
`;


const AboutUsPage = ({data}) => {
const [openModal, setOpenModal] = useState(false);

const fullData = data.allStrapiAbout.nodes[0];
const projectsData = data.allStrapiProject.nodes;
const carouselData = projectsData.map(item => {
    return {
        title: item.title,
        description: item.description.substr(0, 60) + "...",
        img: item.carousel[0].localFile.publicURL,
        url: item.id
    }
})

console.log(carouselData)
const toggleOpenModal = () => {
    if (!openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setOpenModal(!openModal);
}

    return(
        <div>
            <Header/>
            {
                openModal ?
                    (<Modal toggleOpenModal={toggleOpenModal}/>)
                    :
                    (
                        <>
                            <ContainerL style={{margin: "0 auto"}}>
                                <Otstup></Otstup>
                                <Tittle>
                                    {fullData.title}
                                </Tittle>
                                <Border/>
                                <ContainerAbout>
                                    <Text>
                                        {fullData.description}
                                    </Text>
                                    <BlockStroitel>
                                        <div>
                                            <Text2 style={{marginTop: 120, marginRight: 30}}>
                                                {fullData.mainText}
                                            </Text2>
                                        </div>
                                        <BlockImg>
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                                <Img img={fullData.mainImage.localFile.publicURL}>
                                                    <BackImg>

                                                    </BackImg>
                                                </Img>

                                            </div>
                                            <div style={{maxWidth: 90}}>
                                                <Logo style={{transform: 'rotate(0.75turn)'}} className={"svg-logo"}/>
                                            </div>
                                        </BlockImg>
                                    </BlockStroitel>
                                </ContainerAbout>
                                <CardsAchives data={fullData.achievement}/>
                                <Partners/>

                            </ContainerL>

                            <ContainerCarousel>
                                <div style={{paddingBottom: 160}}>
                                    <CarouselProjects items={carouselData} maxWidth={2000}/>
                                </div>
                            </ContainerCarousel>
                            <Footer toggleOpenModal={toggleOpenModal}/>
                        </>
                    )}

        </div>
    );

}

export default AboutUsPage;

export const query = graphql`
    query MyQuery {
        allStrapiAbout {
            nodes {
                title
                description
                mainText
                achievement {
                    description
                    title
                    id
                    image {
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED)
                            }
                        }
                    }
                }
                mainImage {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        allStrapiProject {
            nodes {
                title
                description
                id
                carousel {
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
`
